import React, {useState} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function Resetpass2(){
  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const baseUrl = "https://api.instavoicing.com/api/reset_password";
  const [state , setState] = useState({
    otp: '',
    password: '',
    cpassword: '',
})
  var postData = {
      "otp": state.otp,
      "password": state.password,
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
  

  const handleLoginSubmit = (e) => {
    if (!state.otp || !state.password|| !state.cpassword){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else if(state.password=!state.cpassword){
        MySwal.fire({
            title: <p>Password and confirm password must match</p>,
            didOpen: () => {
            },
          }).then(() => {
          })
    }   
    else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(baseUrl, postData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
          Swal.fire({
            title: 'Successful',
            text: "Your password has been changed.",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Okay",
            closeOnCancel: true,
          }).then(result => {
        window.location.href="/";
          })
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
        },
      }).then(() => {
      })
    }



   
  }
      return ( 
        <div>

<div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"  style={{backgroundColor: '#fff'}}>
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                {/* <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="/metronic8/demo8/../demo8/index.html" action="#"> */}
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
                  </div>
                  {/* <div className="separator separator-content my-14"> */}
                    <span className="w-125px text-gray-500 fw-semibold fs-7">Change your password</span>
                  {/* </div> */}
                  <div className="fv-row mb-8">
                  <input type="password" placeholder="otp" value={state.otp} onChange={handleChange} id="otp" name="otp" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-8">
                  <input type="password" placeholder="New password" value={state.password} onChange={handleChange} id="password" name="password" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-8">
                  <input type="password" placeholder="Confirm new password" value={state.cpassword} onChange={handleChange} id="cpassword" name="cpassword" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                 
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />
                    <a href="/" className="link-primary">Back to login</a>
                  </div>
                  <div className="d-grid mb-10">
                  <button onClick={handleLoginSubmit} className="btn btn-primary">
                      <span className="indicator-label">Submit</span>
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
                    <a href="signup" className="link-primary">Sign up</a></div>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundColor: '#f6f6f6'}}>
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20" src="../../../assets/media/illustrations/sketchy-1/17.png" alt="" />
              <h1 className="d-none d-lg-block text-muted fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
              <div className="d-none d-lg-block text-muted fs-base text-center">Receive payments easily and comfortably,
                <a href="#" className="opacity-75-hover text-warning fw-bold me-1">Upload Invoices</a> and send to your customers for quick feedback.
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default Resetpass2;
