import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import SignIn1 from './component/signin1/signin1';
import Signup from './component/signin1/signup';
import Signup2 from './component/signin1/signup2';
import Signup3 from './component/signin1/signup3';
import Dashboard from './component/dashboard/dashboard';
import logout from './component/dashboard/logout';
import Newinvoice from './component/dashboard/newinvoice';
import Payments from './component/transactions/payment';
import Newreceipt from './component/transactions/newreceipt';
import Allreceipts from './component/transactions/allreceipts';
import Allquotes from './component/transactions/allquotes';
import Newquote from './component/transactions/newquote';
import Viewquote from './component/transactions/viewquote';
import Viewquote2 from './component/transactions/viewquote2';
import Receipt from './component/transactions/receipt';
import Resetpass from './component/signin1/resetpassword';
import Resetpass2 from './component/signin1/resetpassword2';
import Taxreturn from './component/transactions/taxreturn';
import Changepassword from './component/users/changepassword';
import Subuser from './component/users/subuser';
import Subscriptions from './component/users/subscriptions';

export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={SignIn1}/>
          <Route path="/signin" component={SignIn1}/>
          <Route path="/resetpass" component={Resetpass}/>
          <Route path="/subuser" component={Subuser}/>
          <Route path="/subscriptions" component={Subscriptions}/>
          <Route path="/resetpass2" component={Resetpass2}/>
          <Route path="/Signup" component={Signup}/>
          <Route path="/Signup2" component={Signup2}/>
          <Route path="/Signup3" component={Signup3}/>
          <Route path="/newinvoice" component={Newinvoice}/>
          <Route path="/taxreturn" component={Taxreturn}/>
          <Route path="/newreceipt" component={Newreceipt}/>
          <Route path="/newquote" component={Newquote}/>
          <Route path="/viewquote" component={Viewquote}/>
          <Route path="/receipt" component={Receipt}/>
          <Route path="/viewquote2" component={Viewquote2}/>
          <Route path="/allreceipts" component={Allreceipts}/>
          <Route path="/allquotes" component={Allquotes}/>
          <Route path="/payments" component={Payments}/>
          <Route path="/changepassword" component={Changepassword}/>
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/logout" component={logout}/>
         
            
          
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}


