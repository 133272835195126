import React from 'react';


function logout(){
  
  if (!sessionStorage.getItem('instv_tokenize')) {
    console.log('no auth token set');
    window.location.href="signin";
  } else {
    sessionStorage.removeItem('instv_tokenize');
    window.location.href="signin";
  }




      return (
           <div></div>  
        );
  };

export default logout;
