import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyCMVBok0VQYDzo70FPucxZ32hdkhkYUjpA",
    authDomain: "justgymfitness-fbb1a.firebaseapp.com",
    projectId: "justgymfitness-fbb1a",
    storageBucket: "justgymfitness-fbb1a.appspot.com",
    messagingSenderId: "577298204757",
    appId: "1:577298204757:web:39647e94d59112d34a2cdc",
    measurementId: "G-ND0Z04XGNL",
});

 
// Firebase storage reference
const storage = getStorage(app);
export default storage;