import React, {useState,useEffect} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function Signup(){
  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const baseUrl = "https://api.instavoicing.com/api/register";
  // const baseUrl = "http://localhost:5900/api/register";
  const [isRef1Visible, setRef1Visible] = useState(true);
  const [fileBase64, setFileBase64] = useState('');
  const [state , setState] = useState({
    email: '',
    password: '',
    fname: '',
    lname: '',
    phone: '',
    business_name: '',
    business_email: '',
    business_address: '',
    business_logo: '',
    account_name: '',
    account_bank: '',
    account_number: ''  
})


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  var postData = {
      "email": state.email,
      "password": state.password,
      "firstname": state.fname,
      "lastname": state.lname,
      "phonenumber": state.number,
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

    let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const handleLoginSubmit = (e) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (!state.fname || !state.lname|| !state.password|| !state.email|| !state.number){
      MySwal.fire({
        title: <p>Please fill all required fields</p>,
        didOpen: () => {
        },
      }).then(() => {})
    }else if(!isValidEmail(state.email)) {
      MySwal.fire({
          title: <p>Email is invalid</p>,
          didOpen: () => {},
        }).then(() => {});
  
      }else if(!passwordRegex.test(state.password)) {
        MySwal.fire({
            title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
            didOpen: () => {},
          }).then(() => {});
    }else{
      MySwal.showLoading()
       localStorage.setItem('instavoicingsignuppersonaldata', JSON.stringify(postData))
       window.location.href='signup3'
        
    }
  }


      return ( 
        <div>

<div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"  style={{backgroundColor: '#fff'}}>
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {isRef1Visible && (
              <div className="w-lg-500px p-10" id='ref1'>
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
                  </div>
                  <div className="separator separator-content my-10">
                    Personal
                  </div>
                  <div className='row'>
                  <div className="fv-row mb-3 col-lg-6">
                    <label>First Name <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.fname} onChange={handleChange} id="fname" name="fname" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row col-lg-6">
                  <label>Last Name <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.lname} onChange={handleChange} id="lname" name="lname" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  </div>

                  <div className='row'>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>Email <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>phone <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.number} onChange={handleChange} id="number" name="number" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 col-lg-6">
                    <p>Create Password <span style={{color: 'red'}}>*</span></p>             
                 <div className='input-group'>
                    <input type={showPassword ? 'text' : 'password'}  value={state.password} onChange={handleChange} id="password" name="password" className="form-control bg-transparent" /> 
                      <span class="input-group-text" id="basic-addon2">
                          <i class="fa fa-eye" onClick={togglePasswordVisibility}></i>
                      </span>
                  </div>
                  <br/>
                  </div>
                  <div className='row'>
                  <div className="text-gray-500 text-center fw-semibold fs-6 col-lg-6" >Already registered?
                    <a href="/" className="link-primary">&nbsp; Sign in</a></div>
                    <div className="d-grid mb-5 col-lg-6" >
                  <button onClick={handleLoginSubmit} className="btn btn-primary">
                      Next - Business Information
                    </button>
                  </div>
                  </div>
                  </div>
                 
                  <div className='row'>
                 
                
                  </div>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                  
                {/* </form>  */}
              </div>
            )}

          {/* {isRef2Visible && (
              <div className="w-lg-500px p-10 " id='ref2'>
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
                  </div>
                  <div className="separator separator-content my-14">
                  </div>
                  <div className='row'>
                  <div className="fv-row mb-3 col-lg-12">
                    <label>Business Logo</label>
                  <input type="file" value={state.business_logo} onChange={handleChange} id="business_logo" name="business_logo" autoComplete="off" className="file-inpute form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>Business Name</label>
                  <input type="text" placeholder="Business Name" value={state.business_name} onChange={handleChange} id="business_name" name="business_name" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row col-lg-6">
                  <label>Business Address</label>
                  <input type="text" placeholder="Business Address" value={state.business_address} onChange={handleChange} id="business_address" name="business_address" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>Business Email</label>
                  <input type="text" placeholder="Email" value={state.business_email} onChange={handleChange} id="business_email" name="business_email" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6 col-lg-6" >Already a Member?
                    <a href="/" className="link-primary">Sign in</a></div>
                  <div className='row'>
                  <div className="d-grid mb-5 col-lg-6" >
                  <button onClick={goback} className="btn btn-danger">
                      <span className="indicator-label">Back</span>
                    </button>
                  </div>
                  <div className="d-grid mb-5 col-lg-6" >
                  <button onClick={handleFileChange} className="btn btn-primary">
                      <span className="indicator-label">Submit</span>
                    </button>
                  </div>
                  </div>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
               
              </div>
            )} */}

            </div>

          </div>
          <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundColor: '#f6f6f6'}}>
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20" src="../../../assets/media/illustrations/sketchy-1/17.png" alt="" />
              <h1 className="d-none d-lg-block text-muted fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
              <div className="d-none d-lg-block text-muted fs-base text-center">Receive payments easily and comfortably,
                <a href="#" className="opacity-75-hover text-warning fw-bold me-1">Upload Invoices</a> and send to your customers for quick feedback.
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default Signup;
