import React, {useState} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function SignIn1(){
  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const baseUrl = "https://api.instavoicing.com/api/login";
  // const baseUrl = "http://localhost:5900/api/login";
  const [state , setState] = useState({
    email: '',
    password: '',
})
  var postData = {
      "email": state.email,
      "password": state.password,
  };
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};
  

  const handleLoginSubmit = (e) => {
    if (!state.email || !state.password){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(baseUrl, postData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
            sessionStorage.setItem("instv_tokenize", JSON.stringify(response.data.data.accessToken));
            sessionStorage.setItem("instv_fname", JSON.stringify(response.data.data.firstname));
            sessionStorage.setItem("instv_fi", JSON.stringify(response.data.data.id));
            window.location.href="dashboard";
          })
          .catch((err) => {
            Swal.close();
            console.log("AXIOS ERROR: ", err);
            
            if (!err.response || !err.response.data || !err.response.data.message) {
              return MySwal.fire('Network Error, Please try again');
            } else {
              return MySwal.fire(err.response.data.message);
            }
          })
          
        },
      }).then(() => {
      })
    }
  }
      return ( 
        <div>

<div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"  style={{backgroundColor: '#fff'}}>
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                  </div>
                  <div className="separator separator-content my-14">
                    <span className="w-125px text-gray-500 fw-semibold fs-7">With email</span>
                  </div>
                  <div className="fv-row mb-8">
                  <input type="text" placeholder="Email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 input-group">
                  <input type={showPassword ? 'text' : 'password'} placeholder="Password" value={state.password} onChange={handleChange} id="password" name="password" className="form-control bg-transparent" /> 
                      <span class="input-group-text" id="basic-addon2">
                          <i class="fa fa-eye" onClick={togglePasswordVisibility}></i>
                      </span>
                  </div>
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />
                    <a href="resetpass" className="link-primary">Forgot password ?</a>
                  </div>
                  <div className="d-grid mb-10">
                  <button onClick={handleLoginSubmit} className="btn btn-primary">
                      <span className="indicator-label">Sign In</span>
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">Not registered?
                    <a href="signup" className="link-primary"> &nbsp;Sign up</a></div>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundColor: '#f6f6f6'}}>
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20" src="../../../assets/media/illustrations/sketchy-1/17.png" alt="" />
              <h1 className="d-none d-lg-block text-muted fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
              <div className="d-none d-lg-block text-muted fs-base text-center">Receive payments easily and comfortably,
                <a href="#" className="opacity-75-hover text-warning fw-bold me-1">Upload Invoices</a> and send to your customers for quick feedback.
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default SignIn1;
