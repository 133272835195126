import React, {useState,useEffect} from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';


  function Signup3(){
  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const baseUrl = "https://api.instavoicing.com/api/register";
  // const baseUrl = "http://localhost:5900/api/register";
  const [isRef1Visible, setRef1Visible] = useState(true);
  const [fileBase64, setFileBase64] = useState('');
  const mypersonaldata = localStorage.getItem('instavoicingsignuppersonaldata');
  const parseddata = JSON.parse(mypersonaldata)
  console.log(parseddata)
  const [state , setState] = useState({
    // email: '',
    // password: '',
    // fname: '',
    // lname: '',
    // phone: '',
    business_name: '',
    business_email: '',
    business_address: '',
    business_logo: '',
    account_name: '',
    account_bank: '',
    account_number: ''  
})


  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  var postData = {
      "email": parseddata.email,
      "password": parseddata.password,
      "firstname": parseddata.firstname,
      "lastname": parseddata.lastname,
      "phonenumber": parseddata.phonenumber,
      "business_name": state.business_name,
      "business_address": state.business_address,
      "account_name": state.account_name,
      "account_bank": state.account_bank,
      "account_number": state.account_number,
      "business_email": parseddata.email,
      "logo": 'intavoicin',
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

    let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const handleLoginSubmit = (e) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (!parseddata.firstname || !parseddata.lastname|| !parseddata.password|| !parseddata.email|| !parseddata.phonenumber||!state.business_name || !state.business_address|| !state.account_bank||!state.account_name||!state.account_number){
      console.log(postData)
        MySwal.fire({
        title: <p>Please fill all required fields</p>,
        didOpen: () => {
        },
      }).then(() => {})
    }else if(!isValidEmail(parseddata.email)) {
      MySwal.fire({
          title: <p>Email is invalid</p>,
          didOpen: () => {},
        }).then(() => {});
  
      }else if(!passwordRegex.test(parseddata.password)) {
        MySwal.fire({
            title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
            didOpen: () => {},
          }).then(() => {});
    }else{
        MySwal.fire({
      title: <p>please wait...</p>,
      didOpen: () => {
        MySwal.showLoading()
        axios.post(baseUrl, postData, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
          Swal.close()
          Swal.fire({
              // title: response.data.data,
              text: response.data.message,
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Okay",
              closeOnCancel: true,
            }).then(result => {
              const myidd = response.data.data.userId;
              console.log(myidd);
              window.location.href = "signup2?myidd=" + myidd;
            })
        })
        .catch((err) => {
          Swal.close()
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.data)
        })
      },
    }).then(() => {
    })
    }
  }


      return ( 
        <div>

<div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"  style={{backgroundColor: '#fff'}}>
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {isRef1Visible && (
              <div className="w-lg-500px p-10" id='ref1'>
                {/* <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="/metronic8/demo8/../demo8/index.html" action="#"> */}
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
                  </div>
                  <div className="separator separator-content my-10">
                    Business
                  </div>
                  <div className='row'>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>Business Name <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.business_name} onChange={handleChange} id="business_name" name="business_name" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row col-lg-6">
                  <label>Business Address <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.business_address} onChange={handleChange} id="business_address" name="business_address" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row col-lg-6">
                  <label>Bank Account Number <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.account_name} onChange={handleChange} id="account_name" name="account_name" autoComplete="off" className="form-control bg-transparent" />
                  </div>

                  <div className="fv-row col-lg-6">
                  <label>Transit number <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.account_number} onChange={handleChange} id="account_number" name="account_number" autoComplete="off" className="form-control bg-transparent" />
                  </div>
               <br/>
               <br/>
               <br/>
               <br/>
                  <div className="fv-row col-lg-6">
                  <label>Financial institution number <span style={{color: 'red'}}>*</span></label>
                  <input type="text"  value={state.account_bank} onChange={handleChange} id="account_bank" name="account_bank" autoComplete="off" className="form-control bg-transparent" />
                  </div>    

                  {/* <div className="fv-row mb-3 col-lg-6">
                  <label>Business Email</label>
                  <input type="text" placeholder="Email" value={state.business_email} onChange={handleChange} id="business_email" name="business_email" autoComplete="off" className="form-control bg-transparent" />
                  </div> */}
                
                  </div>
                  
                 <br/>
                 <br/>
                  
                  <div className='row'>
                  {/* <div className="text-gray-500 text-center fw-semibold fs-6 col-lg-6" >Already registered?
                    <a href="/" className="link-primary">&nbsp; Sign in</a></div> */}
                  <div className="d-grid mb-5 col-lg-6" >
                      <a href='/signup' className="btn btn-danger">
                      <span className="indicator-label">Back - Personal Data</span>
                    </a>
                    </div>
                  <div className="d-grid mb-5 col-lg-6" >
                  <button onClick={handleLoginSubmit} className="btn btn-primary">
                      <span className="indicator-label">Next - Verification</span>
                    </button>
                  </div>
                  </div>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                  
                {/* </form>  */}
              </div>
            )}

          {/* {isRef2Visible && (
              <div className="w-lg-500px p-10 " id='ref2'>
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
                  </div>
                  <div className="separator separator-content my-14">
                  </div>
                  <div className='row'>
                  <div className="fv-row mb-3 col-lg-12">
                    <label>Business Logo</label>
                  <input type="file" value={state.business_logo} onChange={handleChange} id="business_logo" name="business_logo" autoComplete="off" className="file-inpute form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>Business Name</label>
                  <input type="text" placeholder="Business Name" value={state.business_name} onChange={handleChange} id="business_name" name="business_name" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row col-lg-6">
                  <label>Business Address</label>
                  <input type="text" placeholder="Business Address" value={state.business_address} onChange={handleChange} id="business_address" name="business_address" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="fv-row mb-3 col-lg-6">
                  <label>Business Email</label>
                  <input type="text" placeholder="Email" value={state.business_email} onChange={handleChange} id="business_email" name="business_email" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6 col-lg-6" >Already a Member?
                    <a href="/" className="link-primary">Sign in</a></div>
                  <div className='row'>
                  <div className="d-grid mb-5 col-lg-6" >
                  <button onClick={goback} className="btn btn-danger">
                      <span className="indicator-label">Back</span>
                    </button>
                  </div>
                  <div className="d-grid mb-5 col-lg-6" >
                  <button onClick={handleFileChange} className="btn btn-primary">
                      <span className="indicator-label">Submit</span>
                    </button>
                  </div>
                  </div>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
               
              </div>
            )} */}

            </div>

          </div>
          <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundColor: '#f6f6f6'}}>
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20" src="../../../assets/media/illustrations/sketchy-1/17.png" alt="" />
              <h1 className="d-none d-lg-block text-muted fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
              <div className="d-none d-lg-block text-muted fs-base text-center">Receive payments easily and comfortably,
                <a href="#" className="opacity-75-hover text-warning fw-bold me-1">Upload Invoices</a> and send to your customers for quick feedback.
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      );

  };

export default Signup3;
